import React, { FC, memo } from 'react';
import * as styles from './Filter.module.scss';
import FilterButton from './FilterButton';
import type { Props as FilterButtonProps } from './FilterButton';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filters: Omit<FilterButtonProps, 'name' | 'check'>[];
  type?: FilterButtonProps['type'];
}

let filterId = 0;

const Filter: FC<Props> = ({ onChange, filters, type = 'checkbox' }) => {
  filterId++;

  return (
    <div className={styles.filter}>
      {
        filters.map(filter => (
          <FilterButton
            key={`filter${filter.value}`}
            text={filter.text}
            value={filter.value}
            iconName={filter.iconName}
            name={`filter${filterId}`}
            onChange={onChange}
            checked={filter.checked}
            type={type}
          />
        ))
      }
    </div>
  );
};

export default memo(Filter);
