import React, { FC } from 'react';
import * as styles from './FilterButton.module.scss';

export interface Props {
  text: string;
  value: string;
  name: string;
  iconName?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'radio' | 'checkbox';
}

const FilterButton: FC<Props> = ({ text, checked, iconName, value, name, onChange, type = 'checkbox' }) => {

  console.log(iconName)

  return (
    <div className={styles.filter}>
      <input
        className={styles.filterInput}
        id={`${name}${value}`}
        name={name}
        value={value}
        onChange={onChange}
        defaultChecked={checked}
        type={type}
      />

      <label className={`${styles.filterLabel} ${iconName && styles.withIcon}`} htmlFor={`${name}${value}`}>
        {iconName && <i className={`fas fa-${iconName} ${styles.icon}`} />}
        <span>
          {text}
        </span>
      </label>
    </div>
  );
};

export default FilterButton;