import React, { useState } from "react";
import { graphql } from "gatsby";
import * as styles from './index.module.scss';
import { motion } from 'framer-motion';
import Filter from "../../components/Buttons/Filter";
import PageTitle from "../../components/Titles/PageTitle";
import Project from "../../components/Projects/Project";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectPage = (props: any) => {
  const { projects, services } = props.data;

  const [filters, setFilters] = useState<string | undefined>(undefined);
  const [imageModal, setImageModal] = useState<any>(undefined);

  const filterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(e.target.value);
  };

  const filteredProjects = projects.edges.filter((project: any) => {
    if (!filters) {
      return true;
    }
    return filters === project.node.service._id;
  });

  const closeImageModal = () => {
    setImageModal(undefined);
  };

  return (
    <main>
      <section>
        {
          imageModal && (
            <motion.div className={styles.imageModal}>
              <motion.div layoutId={imageModal.id} className={styles.imageModalContainer}>
                <img
                  src={imageModal.url}
                  className={styles.image}
                  alt="image"
                />
              </motion.div>
              <button onClick={closeImageModal} className={styles.closeButton}>
                <i className="fa fa-times"></i>
              </button>
            </motion.div>
          )
        }

        <PageTitle text="Nos projets" />

        <div className={styles.filterContainer}>
          <Filter
            onChange={filterChange}
            type="radio"
            filters={[
              {
                text: 'Tous',
                value: undefined,
                iconName: 'fa fa-th',
                checked: !filters,
              },
              ...services.edges.map((service: any) => ({
                text: service.node.name,
                value: service.node._id,
                iconName: service.node.iconName,
              }))
            ]}
          />
        </div>

        <div className={styles.projectsContainer}>
          {
            filteredProjects.map((project: any) => (
              <Project
                key={project.node._id}
                name={project.node.title}
                createdAt={new Date(project.node._createdAt)}
                category={project.node.service.name}
                categoryLink={`/services/${project.node.service.slug.current}`}
                categoryIcon={project.node.service.iconName}
                images={project.node.images}
                setImageModal={setImageModal}
              />
            ))
          }
        </div>
      </section>
    </main>
  );
};

export const Head = ({ data }: any) => {
  const { general } = data;

  return (
    <>
      <title>Projets | {general.title}</title>
      <meta name="description" content={general.description?.slice(0, 150)} />
    </>
  );
};

export const query = graphql`
  {
    projects: allSanityProject (sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          _id
          _createdAt
          title
          service {
            _id
            name
            iconName
            slug {
              current
            }
          }
          images {
            asset {
              id
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], height: 500, layout: CONSTRAINED)
              url
            }
          }
        }
      }
    }
    
    services: allSanityService {
      edges {
        node {
          _id
          name
          iconName
        }
      }
    }

    general: sanityGeneral {
      title
      description
    }

  }
`;

export default ProjectPage;