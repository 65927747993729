import React, { FC, memo } from 'react'
import * as styles from './PageTitle.module.scss'

interface Props {
  text: string
}

const PageTitle: FC<Props> = ({ text }) => {
  return (
    <h1 className={styles.pageTitle}>
      {text}
    </h1>
  )
}

export default memo(PageTitle)